























import Vue from 'vue'
import NewsItem from '@/components/pc/newsItem.vue';
import { Pagination, Spin } from "ant-design-vue";
import {getNews} from '@/service/api'
export default Vue.extend({
	name: 'newsLists',
	data() {
		return {
			total: 0,
			currentPage: 1,
			pageSize: 10,
			allLists: [],
			newsLists: [],
			isLoading: false
		}
	},
	components: {
		NewsItem,
    Pagination,
		Spin
	},
	methods: {
		handleChange(page: number) {
			this.currentPage = page
			this.getLists()
		},
		getLists() {
			const copy = JSON.parse(JSON.stringify(this.allLists))
			this.newsLists = copy.splice((this.currentPage - 1) * 10, 10)
		},
		getNewsList() {
			const language = window.sessionStorage.getItem('language') || 'zh-CN'
			this.isLoading = true
			getNews(language).then(res => {
				this.allLists = res.rows
				this.allLists.forEach((item: {cover: string}) => {
					item.cover = item.cover.replace(/public.33.cn/g, 'public.yuan.org')
				})
				console.log(this.allLists)
				this.total = this.allLists.length
				this.getLists()
				this.isLoading = false
			}).catch(e => {
				this.isLoading = false
			})
		}
	},
	created() {
		this.getNewsList()
	},
	watch: {
		$route(n, o) {
			// this.currentPage = 1
			if (n.query.lang !== o.query.lang) {
				this.currentPage = 1
			}
			this.getNewsList()
		}
	}
})
